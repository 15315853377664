<template>
  <div class="box">
    <ul class="login_way" ref="box">
        <li :class="type == 1 ? 'active' : ''" @click="changeType(1)">
            已启用
        </li>
        <div></div>
        <li :class="type == 2 ? 'active' : ''" @click="changeType(2)">
            未激活
        </li>
        <li :class="type == 3 ? 'active' : ''" @click="changeType(3)">
            已使用/过期
        </li>
    </ul>
    <div class="contents" :style="'height:' + boxHeight + 'px'">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <div class="tickets" v-for="(item,index) in couponList" :key="index">
        <div class="l-tickets">
          <div class="couponPrice" :style="{'color':type==1?'#EA3737':type==2?'#EA3737':'#C3C9D5'}">
            30<span>天</span>
            <!-- <div class="lock" v-if="type == 2">
                <img src="@/assets/images/selectpack/lock.png" alt="">
                <p>锁定中未激活</p>
            </div> -->
            
            <div :class="[type==3?'unexpireTime':'expireTime']">套餐有效期</div>
            </div>
            <div class="couponInfo">
              <div :class="[item.isused?'couponInfo_name1':item.isexpire?'couponInfo_name2':'couponInfo_name']">
                {{item.coupon_name}}
              </div>
              <div class="couponInfo_use">
                体验套餐不可用
              </div>
              <div class="couponInfo_time" v-if="type==1">
                {{item.start_time}} 至 {{item.expire_time}}
              </div>
              <div class="couponInfo_time" v-else-if="type == 2">
                未激活
              </div>
              <div class="couponInfo_time" v-else>
                {{item.isexpire?item.start_time + '至' + item.expire_time:item.isused == 1?'已使用':item.start_time + '至' + item.expire_time}}
              </div>

              <!-- <div class="couponInfo_tip">
                适用范围：体验套餐不可用
              </div> -->
            </div>
        </div>
        <div class="r-tickets">
          <div class="toUse">
            <!-- <div class="btns">去使用</div> -->
            <button v-if="type != 3" :class="[type==2?'disBtns':'btns']" :disabled = "type ==2" @click="toUse(item)">去使用</button>
            <button v-else :class="[item.isexpire?'expireBtn':'hadBtns']" :disabled = "true">{{item.isused == 1?'已使用':item.isexpire?'已过期':'已使用'}}</button>
          </div>
        </div>
        </div>
      </van-list>
    </div>
    <van-empty v-if="couponList.length <= 0" description="暂无优惠券" />
    <van-overlay :show="show" @click="show = false" :lock-scroll="false">
      <div class="wrapper" @click.stop v-if="isShowList">
        <div class="block">
          <div class="heard">
            <div class="heard_left">选择已添加设备</div>
            <div class="heard_right" @click="close">取消</div>
          </div>
          <van-radio-group v-model="radio" style="width:100%" class="scollow">
            <div class="deviceList_q" v-for="(item,index) in deviceList" :key="index">
              <div class="deviceList_left">
                <div class="deviceList_name">
                  <div class="names">{{item.alias_name}}</div>
                </div>
                <div class="deviceList_iccid" style="margin-top:10px">
                  <div class="names">设备号: {{item.iccid}}</div>
                </div>
              </div>
              <div class="deviceList_right">
                <van-radio :name="index"></van-radio>
              </div>
            </div>
          </van-radio-group>
          <div class="foot">
            <button @click="nextStup">确认设备</button>
          </div>  
        </div>
      </div>
      <div class="poster" @click.stop v-else>
        <div class="poster1">
          <div class="heard_tip">已选择要延长的设备</div>
          <div class="main">
            <div class="main_title">{{deviceList[radio].alias_name}}</div>
            <div class="main_iccid">设备号：{{deviceList[radio].iccid}}</div>
            <div class="main_time">有效期 使用前：{{deviceList[radio].exTime}}  使用后：{{deviceList[radio].exFuture}}</div>
            <div class="main_btn">
              <button class="main_btn_left" @click="close">取消使用</button>
              <button class="main_btn_right" @click="useCoupon" >确认使用</button>
            </div>
          </div>
          <div class="close" @click="close">
            <img src="../../assets/images/invite/close.png" alt="">
          </div>  
        </div>
      </div>
  </van-overlay>
  </div>
</template>

<script>
import {getCouponsList,useCoupon} from '@/untils/api.js'
export default {
  data(){
    return{
      type: 1,//tab选项卡
      couponList:[],//优惠券列表
      gettime:'',//当前时间
      page:1,
      limit:5,
      boxHeight:0,//盒子高度
      totalPage:1,//总页数
      loading: false,
      finished: false,
      show: false,//是否展示弹出层
      radio: 1,
      isShowList:true,//是否展示设备列表
      deviceList:[],//设备列表
      nowDeviceIccid:{},//当前选中得设备号信息
      nowCoupon:{},//当前得优惠券
    }
  },
  created(){
    if(this.$route.query.phone){
      window.sessionStorage.setItem('phone',this.$route.query.phone)
    }
  },
  mounted(){
    const selectWrap = document.querySelector ('.contents')
    selectWrap.addEventListener ('scroll', this.scrollLoadMore);
    this.getCouponsList(this.type)
    this.$nextTick (() => {
      const box = this.$refs['box'];
      this.boxHeight = window.innerHeight - box.getBoundingClientRect().bottom
    })
  },
  methods:{
    //关闭遮罩层
    close(){
      this.show = false
    },
    // 点击确认设备
    nextStup(){
      const nowtime = Math.floor(new Date().getTime() / 1000)
      this.nowDeviceIccid = this.deviceList[this.radio]
      if(this.nowDeviceIccid.c_extime > nowtime && this.nowDeviceIccid.pay_status == 1){
        this.isShowList = false
        // this.useCoupon()
      }else{
        // console.log('当前设备未激活');
        window.sessionStorage.setItem('iccid',this.nowDeviceIccid.iccid)
        this.$router.push("/selectpack")
      }
    },
    // 去使用
    toUse(item){
      this.show= true
      this.isShowList = true
      this.nowCoupon = item
    },
    // 加载更多
    scrollLoadMore(){
      let scrollWrap = document.querySelector ('.contents');
      var currentScrollTop = scrollWrap.scrollTop;
      var currentOffsetHeight = scrollWrap.scrollHeight;
      var currentClientHeight = scrollWrap.clientHeight;
      if ((currentScrollTop + currentClientHeight == currentOffsetHeight)){
        if(this.page <= this.totalPage){
          this.loading = true
          this.page = this.page + 1
          this.getCouponsList(this.type)
        }else{
          this.loading = false
          this.finished =true
        }
      }
    },
    // 改变tab选项卡
    changeType(item) {
      this.type = item;
      this.getCouponsList(item)
      this.couponList = []
    },

    // 获取优惠券列表
    getCouponsList(item){
      let data = {
        // phone:'15825858556',
        phone:sessionStorage.getItem("phone"),
        state:item,
        page:this.page,
        limit:this.limit,
        type:1,// 优惠劵页面传1   套餐订购页面传2  
      }
      this.loading = true
      this.finished =false
      getCouponsList({data}).then(res =>{
        this.getCurrentTime()
        if(res.data.code == 1) {
          this.totalPage = Math.ceil(res.data.list.total/this.limit)
          if(res.data.list.data.length == 0){
            this.loading = false
            this.finished =true
          }
          if(this.page == this.totalPage){
              this.loading = false
              this.finished =true  
          }
          res.data.list.data.forEach(element => {
            element.expire_day = this.DateDiff(this.gettime,element.expire_time.substr(0,10))
            if(element.expire_day < 0){
              element.isexpire = true
            }
            if(element.expire_day <= 3){
              element.isShowExpireTime = true
            }
            element.price = Math.floor(element.value)
            element.start_time = element.start_time.substr(0,10)
            element.expire_time= element.expire_time.substr(0,10)
          });
          this.couponList = [...this.couponList, ...res.data.list.data]
          this.deviceList = res.data.info.iccids
          this.deviceList.forEach(element => {
            const dateObj = new Date(element.c_extime*1000)
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1; // JavaScript 的月份从0开始，所以需要加1
            const day = dateObj.getDate();
            element.exTime = `${year}-${month}-${day}`
            const futureTime = dateObj.getTime() + 30 * 24 * 60 * 60 * 1000; // 加上30天的毫秒数
            const futureDateObj = new Date(futureTime);
            const futureYear = futureDateObj.getFullYear();
            const futureMonth = futureDateObj.getMonth() + 1;
            const futureDay = futureDateObj.getDate();
            element.exFuture = `${futureYear}-${futureMonth}-${futureDay}`  
          });
          this.radio = this.deviceList.findIndex(item => item.iccid == res.data.info.iccid)
          this.nowDeviceIccid = this.deviceList[this.radio]
        }else{
          this.$toast(res.data.msg)
        }
      })
    },
    DateDiff(date1 , date2) {    //date1和date2是2002-12-18格式
      var date1Str = date1.split("-");//将日期字符串分隔为数组,数组元素分别为年.月.日
      //根据年 . 月 . 日的值创建Date对象
      var date1Obj = new Date(date1Str[0],(date1Str[1]-1),date1Str[2]);
      var date2Str = date2.split("-");
      var date2Obj = new Date(date2Str[0],(date2Str[1]-1),date2Str[2]);
      var t1 = date1Obj.getTime();
      var t2 = date2Obj.getTime();
      var dateTime = 1000*60*60*24; //每一天的毫秒数
      var days = Math.floor(((t2-t1)/dateTime));//计算出两个日期的天数差
      // console.log(minusDays,'两个相差天数');
      // var days = Math.abs(minusDays);//取绝对值
      return days;
    },
    getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()-0 +1 <10? '0' + ((new Date().getMonth()-0) +1) : new Date().getMonth()-0 +1;
        let dd = new Date().getDate();
        // let hh = new Date().getHours();
        // let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        // let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy+'-'+mm+'-'+dd;
        console.log(_this.gettime)  
    },
    // 使用优惠券
    useCoupon(){
      let data = {
        // phone:'15825858556',
        phone:sessionStorage.getItem("phone"),
        iccid:this.nowDeviceIccid.iccid,
        coupon_id:this.nowCoupon.id
      }
      useCoupon({data}).then(res => {
        if(res.data.code == 1){
          this.$toast(res.data.msg)
          this.show = false
          this.getCouponsList(this.type)
        }else{
          this.$toast(res.data.msg)
          this.show = false
        }
      })
      // 
      
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  .login_way {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      padding: 0px 30px;
      background-color: #ECF0F7;
      height: 44px;
      li{
          // border: 1px solid red;
          padding: 5px 10px;
          color: #AAB3C2;
      }
      .active {
          color: #1e6fff;
          background-color: #E1E9F7;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 700;
      }
  }
  .contents{
    overflow-y: scroll;
  }
  .tickets {
    display: flex;
    padding: 15px;
    width: 100%;
    // height: 115px;
    box-sizing: border-box;
    .l-tickets {
      width: 75%;
      height: 100%;
      background: #fff;
      position: relative;
      background: radial-gradient(circle at right top, transparent 8px,#fff 0) right top / 100% 50% no-repeat,
              radial-gradient(circle at right bottom, transparent 8px, #fff 0) right bottom / 100% 50% no-repeat;
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 10px;
      .couponPrice{
        // color: #EA3737;
        font-size: 36px;
        font-weight: bold;
        font-family: 'bahn';
        position: relative;
        text-align: center;
        
        .lock{
            position: absolute;
            top: -5px;
            left: -10px;
            text-align: center;
            img{
                width: 21px;
                height: 25px;
            }
            p{
                font-size: 12px;
                white-space: nowrap;
                color: #518EF4;
                font-weight: 400;
            }
        }
        span{
          font-size: 16px;
          font-weight: 800;
        }
        .expireTime{
          font-size: 10px;
          font-weight: 500;
          color: #EA3737;
          padding: 1px 2px;
          white-space: nowrap;
          background: rgba(234, 110, 55, .3);
        }
        .unexpireTime{
          font-size: 10px;
          font-weight: 500;
          color: #C8D3E5;
          padding: 1px 2px;
          white-space: nowrap;
          background: rgba(172, 185, 207, .1);
        }
      }
      .couponInfo{
        margin-left: 22px;
        .couponInfo_name{
          font-weight: bold;
          font-size: 16px;
          color: #353131;
        }
        .couponInfo_name1{
          font-weight: bold;
          font-size: 16px;
          color: #C8D3E5;
        }
        .couponInfo_name2{
          font-weight: bold;
          font-size: 16px;
          color: #8D99AF;
        }
        .couponInfo_use{
          font-size: 12px;
          color: #C8D3E5;
          margin: 12px 0 9px;
          font-weight: 400;
        }
        .couponInfo_time{
          font-size: 12px;
          color: #8290A8;
          font-weight: 400;
          margin: 10px 0;
        }
        .couponInfo_tip{
          color: #C2CDE0;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .l-tickets::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      top: 0;
      left: -4px;
      /* 
        语法：
        background:bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit; 
        radial-gradient(shape size at position, start-color, ..., last-color);
      */
      // background: radial-gradient(circle at left center, transparent 4px, skyblue 0) left center / 4px 10px;
    }

    .r-tickets {
      flex: 1;
      background: #fff;
      position: relative;
      background: radial-gradient(circle at left top, transparent 8px,  #fff 0) right top / 100% 50% no-repeat,
              radial-gradient(circle at left bottom, transparent 8px,  #fff 0) right bottom / 100% 50% no-repeat;
      padding: 15px;
      border-radius: 10px;
      
      .toUse{
        height: 100%;
        margin-right: -10px;
        display: flex;
        align-items: center;
        .btns{
          background: linear-gradient(90deg, #206CEB 0%, #518EF4 100%);
          box-shadow: 0px 8px 12px 0px rgba(2,75,183,0.2);
          border-radius: 6px;
          width: 64px;
          height: 32px;
          font-weight: bold;
          font-size: 14px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }
        .disBtns{
          background: #8D99AF;
          border-radius: 6px;
          width: 64px;
          height: 32px;
          font-weight: bold;
          font-size: 13px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }
        .hadBtns{
          background: #EEF3FA;
          border-radius: 6px;
          width: 64px;
          height: 32px;
          font-weight: bold;
          font-size: 13px;
          color: #C8D3E5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }
        .expireBtn{
          background: #C8D3E5;
          border-radius: 6px;
          width: 64px;
          height: 32px;
          font-weight: bold;
          font-size: 13px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }
      }
    }
    .r-tickets::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      top: 0;
      right: -4px;
      // background: radial-gradient(circle at right center, transparent 4px, #fff 0) right center / 4px 10px;
    }
    .r-tickets::before {
      content: '';
      width: 5px;
      background: linear-gradient(to top, #F3F5F7 0%, #F3F5F7 50%, #fff 50%) top left / 10px 20px repeat-y;
      position: absolute;
      left: -2.5px;
      top: 8px;
      bottom: 8px;
    } 

  }
  .wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    .block {
      width: 100%;
      background-color: #fff;
      border-radius: 12px 12px 0px 0px;
      .heard{
        border-radius: 12px 12px 0px 0px;
        background: #F6FBFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        .heard_left{
          font-weight: bold;
          color: #0C60EE;
          font-size: 16px;
        }
        .heard_right{
          background: #EEF3FA;
          border-radius: 4px;
          color: #8D99AF;
          font-size: 14px;
          font-weight: 500;
          padding: 9px 19px;
        }
      }
      .scollow{
        height: 256px;
        overflow-y: scroll;
      }
      .deviceList_q{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        border-bottom: 1px solid #E7ECF1;
        .deviceList_left{
          font-size: 14px;
          font-weight: 500;
          color: #353131;
          .deviceList_name{
            font-weight: bold;
            font-size: 16px;
          }
          .deviceList_iccid{
            font-size: 14px;
          }
        }
      }
      .foot{
        padding: 46px 15px;
        button{
          width: 100%;
          height: 44px;
          line-height: 44px;
          background: #0C60EE;
          border-radius: 22px;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

  }
  .poster{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .poster1{
      height: 311px;
      width: 320px;
      background: url('../../assets/images/invite/poster_bg.png') no-repeat;
      background-size: 100%;
      position: relative;
      .heard_tip{
        color: #F57837;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 77px;
        left: 41px;
      }
      .main{
        width: 100%;
        position: absolute;
        top: 120px;
        margin-top: ;
        text-align: center;
        overflow: hidden;
        .main_title{
          color: #353131;
          font-weight: bold;
          font-size: 18px;
          margin-top: 22px;
        }
        .main_iccid{
          color: #8396A6;
          font-weight: 500;
          font-size: 12px;
          margin-top: 12px;
        }
        .main_time{
          background: #F0F8FF;
          border-radius: 12px;
          color: #0C60EE;
          font-size: 10px;
          width: 240px;
          height: 24px;
          line-height: 24px;
          margin: 0 auto;
          margin-top: 16px;
          padding:0 3px ;
        }
        .main_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 25px;
          color: #FFFFFF;
          font-weight: bold;
          font-size: 14px;
          .main_btn_left{
            width: 100px;
            height: 44px;
            background: linear-gradient(90deg, #E1E8F3 0%, #D2DDEE 100%);
            border-radius: 8px 14px 8px 8px;
            margin-right: 15px;
          }
          .main_btn_right{
            width: 100px;
            height: 44px;
            background: linear-gradient(90deg, #226EEC 0%, #085BE8 100%);
            border-radius: 8px 14px 8px 8px;
          }
        }
      
      }
      .close{
          position: absolute;
          bottom: -60px;
          left: 50%;
          margin-left: -18px;
          img{
            width: 36px;
            height: 36px;
          }
        }
    }
  }
}
</style>